<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <article>
      <asom-card>
        <form class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
          <asom-form-field
            :label="'Title'"
            :state="inputStates('formData.title')"
            error="Title is required"
            required
          >
            <asom-input-text
              v-model="formData.title"
              :state="inputStates('formData.title')"
            />
          </asom-form-field>
          <asom-form-field
            required
            label="Description"
            :state="inputStates('formData.description')"
            error="Description is required"
          >
            <asom-input-textarea
              type="text"
              v-model="formData.description"
              :state="inputStates('formData.description')"
              :maxlength="1000"
            />
          </asom-form-field>
          <asom-form-field
            required
            label="Remarks"
            :state="inputStates('formData.remarks')"
            error="Remarks is required"
          >
            <asom-input-textarea
              type="text"
              v-model="formData.remarks"
              :state="inputStates('formData.remarks')"
              :maxlength="1000"
            />
          </asom-form-field>
          <asom-form-field
            class="col-span-2"
            label="Supporting documents"
            :state="inputStates('formData.files')"
            error="Please upload only one supporting document"
          >
            <asom-upload
              v-model="formData.files"
              :category="attachmentCategories.FAULT_MANAGEMENT"
              :state="inputStates('formData.files')"
            />
            <asom-upload-file-list
              :files="formData.uploadedFiles"
              disabled-remove
            />
          </asom-form-field>
          <div class="col-span-2 flex justify-end space-x-4 pt-8">
            <asom-button
              text="Back"
              variant="secondary"
              @click="$router.go(-1)"
            />
            <asom-button
              text="Update"
              @click="editStationEBooklet"
              :loading="isSubmitting"
            />
          </div>
        </form>
      </asom-card>
    </article>
  </div>
</template>

<script>
import get from "lodash.get";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import {
  getFaultEBookletDetails,
  editFaultEBooklet,
} from "../../../services/faultManagement.service";
import { ATTACHMENT_CATEGORIES } from "@/constants/APIEnums/attachmentEnums";

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [inputStates],
  data() {
    return {
      error: null,
      isSubmitting: false,
      formData: {
        description: null,
        title: null,
        remarks: null,
        files: [],
        uploadedFiles: [],
        lineId: null,
      },
    };
  },
  validations() {
    return {
      formData: {
        description: { required },
        title: { required },
        remarks: { required },
      },
    };
  },
  mounted() {
    this.getEBookletDetails();
  },
  computed: {
    attachmentCategories() {
      return ATTACHMENT_CATEGORIES;
    },
  },
  methods: {
    async getEBookletDetails() {
      const result = await getFaultEBookletDetails({
        faultEBookletId: get(this.$route.params, "faultEBookletId"),
      });
      if (result.success) {
        this.setFormData(get(result.payload, "viewModel"));
      }
    },
    setFormData(data) {
      this.formData.title = get(data, "title", "-");
      this.formData.description = get(data, "description", "-");
      this.formData.remarks = get(data, "remarks", "-");
      let attachments = get(data, "attachments", []);
      this.formData.uploadedFiles = attachments.map((attachment) => {
        attachment.fileId = attachment.attachmentId;
        return attachment;
      });
      this.formData.lineId = get(data, "lineId");
    },
    editStationEBooklet() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.onSubmit();
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async onSubmit() {
      let uploadedIds = [];
      if (this.formData.files) {
        uploadedIds = this.formData.files.map((file) => file.fileId);
      }
      if (this.formData.uploadedFiles) {
        uploadedIds = [
          ...uploadedIds,
          ...this.formData.uploadedFiles.map((file) => file.fileId),
        ];
      }
      this.isSubmitting = true;
      const result = await editFaultEBooklet({
        faultEBookletId: get(this.$route.params, "faultEBookletId"),
        title: this.formData.title,
        remarks: this.formData.remarks,
        description: this.formData.description,
        attachmentIds: uploadedIds,
        lineId: this.formData.lineId,
      });
      if (result.success) {
        this.isSubmitting = false;
        this.$router.go(-1);
      } else {
        this.isSubmitting = false;
        this.error = result.payload;
        this.$scrollTop();
      }
    },
  },
};
</script>
